<template>
    <ul class="card-info">
        <li v-if="showHeader">{{ $translate('card_info', 'Kortfakta') }}</li>
        <li
            v-for="(info, index) in cardInfo"
            :key="index"
        >
            <span>{{ info.header }}</span>
            <span v-html="info.data" />
        </li>
    </ul>
</template>

<script>
import { capitalize } from 'lodash';
export default {
    props: {
        model: {
            type: Object,
            required: true,
            default: () => {}
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        cardInfo() {
            let list = [
                {
                    header: this.$translate('annual_fee', 'Årsavgift'),
                    data: this.model.yearlyCostHtml,
                    show: true
                },
                {
                    header: this.$translate('max_credit', 'Högsta kredit'),
                    data: this.model.getMaxCreditString('Obegränsad'),
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('interest', 'Ränta'),
                    data: this.model.interestString,
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('effective_interest', 'Effektiv ränta'),
                    data: this.model.effectiveInterestString,
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('interest_free', 'Räntefritt'),
                    data: this.model.getInterestFreeDaysString(this.$translate('days', '{days} dagar')),
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('card_type', 'Korttyp'),
                    data: capitalize(this.model.paymentNetwork),
                    show: true
                },
                {
                    header: this.$translate('withdraw_fee', 'Uttagsavgift'),
                    data: this.model.getWithdrawalFeeString('{percentage} (min {amount})', '{percentage} + {amount}'),
                    show: true
                },
                {
                    header: this.$translate('currency_surcharge', 'Valutapåslag'),
                    data: this.model.getCurrencyExchangeFeeString(),
                    show: true
                },
                {
                    header: this.$translate('avi_fee', 'Aviavgift'),
                    data: this.model.getAviFeeString('{paperInvoiceFee} ({eInvoiceFee} e-faktura)'),
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('reminder_fee', 'Påminnelseavgift'),
                    data: this.model.reminderFeeString,
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('late_payment_fee', 'Förseningsavgift'),
                    data: this.model.latePaymentFeeString,
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('overdraft_fee', 'Övertrasseringsavgift'),
                    data: this.model.overdraftFeeString,
                    show: this.model.isCredit
                },
                {
                    header: this.$translate('compare_cards_min_amount_to_pay', 'Minsta belopp att betala'),
                    data: this.model.getInvoiceMinToPayString(),
                    show: true
                }
            ];

            return list.filter(item => item.show);
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-info {
        li::v-deep {
            @include flex(between);
            width: 100%;
            padding: 10px 0;
            @include border(bottom);
            &:last-child { border-bottom: 0; }

            span {
                flex: 1;
                &:first-child {
                    font-weight: bold;
                    padding-right: 10px;
                }
            }

            .standard-yearly-cost {
                color: red;
                margin-left: 5px;
                padding: 0!important;
                text-decoration: line-through;
            }
        }
    }
</style>
